input, textarea, select { border: 1px solid #DCDCDC; color: #777777; font-size: 10pt; border-radius: 5px; padding: 10px; font-family: 'NotoSans'; width: calc(100% - 22px); outline: none; resize: none; }
input:focus, textarea:focus { border-color: #65A3EB; }
input::placeholder, textarea::placeholder { color: #BABABA; }
textarea { vertical-align: top; }
select { background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
    -moz-appearance: none; -webkit-appearance: none; appearance: none;
    font-size: 10pt; width: 100%; padding: 8px 10px; height: 41px; }

.overlay-card { background-color: rgba(0, 0, 0, 0.1); position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.card { background-color: white; padding: 20px 15px; border-radius: 5px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.card-header { display: flex; }
.card-header > img { width: 35px; padding: 0 15px; }
.card-title { font-size: 12pt; color: #474747; text-align: center; }
.card-sub-title { font-size: 10.5pt; color: #b0b0b0; text-align: center; }

button.border { background: none; border: 1px solid #EBEBEB; color: #626262; }
button.border:hover { background-color: #f6f6f6; border-color: #ececec; }
button.border > img { width: 17px; vertical-align: sub; }

.card-divided-2 { display: flex; justify-content: space-between; }
.card-divided-2 > * { width: 49.5%; }
.card-divided-2 > button { height: 35px; }

.card-header-line { width: 150px; height: 1px; background-color: #C9C9C9; margin-top: 10px; }

.spacer-5 { height: 5px; }
.spacer-10 { height: 10px; }
.spacer-20 { height: 20px; }
.spacer-30 { height: 30px; }